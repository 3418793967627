<template>
    <div class="wait-content">
        <div class="bg-img">
          <img :src="image" alt="等待考试">
        </div>
        <div class="bg-txt">
            <p>您来早啦！考试还未开始哦~</p>
        </div>
      <div class="mask"></div>
    </div>

</template>

<script>
export default {
  name: "waiterExam",
  data() {
    return {
        image: require("../../assets/images/waiting.png")
    }
  }

}
</script>

<style scoped lang="scss">
.wait-content {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 20px);
  & * {
    box-sizing: border-box;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 126px;
  .bg-img {
      width: 544px;
      height: 301px;
      margin-bottom: 84px;
      img {
          width: 100%;
          height: 100%;
      }
  }
  .bg-txt {
      p {
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }
  }
  .mask {
      position: fixed;
      top: 60px;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      cursor: not-allowed;
  }
}
</style>